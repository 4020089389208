import { convert } from 'html-to-text';
import { GUIDES_FEATURE, GUIDE_CATEGORIES, NEWS_CATEGORIES, NEWS_FEATURE } from '../constants/content-hub';
import { IRichTextRichText } from '../types/strapi';
import { ContentHubArticle, ContentHubArticleCategory, ConvertedContentHubArticle } from '../types/strapi/overrides';
import { SerializableObject } from './serializeObject';

export const findRichText = (content: ContentHubArticle['attributes']['content'], length?: number) =>
  convert(
    content
      ?.filter((c) => Object.prototype.hasOwnProperty.call(c, 'content') && (c as IRichTextRichText)?.content != null)
      ?.map((c) => (c as IRichTextRichText)?.content)
      ?.join(' ') || '',
    {
      selectors: [
        { selector: 'img', format: 'skip' },
        { selector: 'a', options: { ignoreHref: true, noAnchorUrl: true } },
      ],
    },
  ).substring(0, length || 400);

export const getArticleUrl = (article: ConvertedContentHubArticle) => {
  const { subCategories } = article.attributes;
  const category = subCategories?.data[0].attributes.articleCategory;

  return `/${category}/${subCategories?.data[0].attributes.slug}/${article.attributes.slug}`;
};

export const getArticleImage = (article: ConvertedContentHubArticle | ContentHubArticle): string => {
  if (article?.attributes?.mobilePoster?.data?.attributes?.url != null)
    return article.attributes.mobilePoster.data.attributes.url;
  if (article?.attributes?.tabletPoster?.data?.attributes?.url != null)
    return article.attributes.tabletPoster.data.attributes.url;
  if (article?.attributes?.poster?.data?.attributes?.url != null) return article.attributes.poster.data.attributes.url;
  return '';
};

export const getArticleCategoryParams = () => {
  let params: SerializableObject | undefined;
  let articlesParams: SerializableObject | undefined;
  const categories: ContentHubArticleCategory[] = [];

  // don't add categories if flag is not set for those categories
  if (!GUIDES_FEATURE || !NEWS_FEATURE) {
    params = {};
    articlesParams = {};
    if (!GUIDES_FEATURE) categories.push(...GUIDE_CATEGORIES);
    if (!NEWS_FEATURE) categories.push(...NEWS_CATEGORIES);
    params['filters[$and][0][articleCategory][$notIn]'] = categories as string[];
    articlesParams['filters[$and][0][subCategories][articleCategory][$notIn]'] = categories as string[];
  }

  return { params, articlesParams };
};
